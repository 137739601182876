.NavBar {
    background-color: #d1c8df;
}

.NavbarIcon {
    position: absolute;
    padding-top: 1em;
    padding: 1em;

}

.NavbarList {
    display: flex;
    flex-direction: row-reverse;
}

.NavbarItem {
    width: 10vw;
    padding-top: 1em;
    padding-right: 8vw;
    padding: 1em;
}

.NavbarItemEmpty {
    width: 2vw;
    padding-top: 1em;
    padding-right: 8vw;
    padding: 1em;
}

.NavbarItem:hover {
    background-color: #f6d5e8;
}